<template>
  <div class="container_sign_up">
    <Navbar/>
    <div class="container_sign_up__container_form py-5 py-lg-0">
      <b-container class="h-100">
        <b-row align-h="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="4" align-self="center">
            <form @submit.prevent="sendForm">
              <b-card no-body class="py-5 px-4">
                <b-container class="px-0 px-md-4">
                  <b-row>
                    <b-col>
                      <h3 class="mb-4">Sign Up</h3>
                      <!-- First name -->
                      <b-form-group class="mb-4">
                        <b-form-input type="text" placeholder="First name" trim
                          :class="{'is-invalid': $v.form.first_name.$error}" v-model.trim="$v.form.first_name.$model"></b-form-input>

                        <span role="alert" class="invalid-feedback" v-if="$v.form.first_name.$error">
                          <span v-if="!$v.form.first_name.required">The First name is required</span>
                          <span v-if="!$v.form.first_name.minLength">
                            The First name must have at least {{$v.form.first_name.$params.minLength.min}} characters
                          </span>
                          <span v-if="!$v.form.first_name.maxLength">
                            The First name must be less than {{$v.form.first_name.$params.maxLength.max}} characters
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Last name -->
                      <b-form-group class="mb-4">
                        <b-form-input type="text" placeholder="Last name" trim
                          :class="{'is-invalid': $v.form.last_name.$error}" v-model.trim="$v.form.last_name.$model"></b-form-input>

                        <span role="alert" class="invalid-feedback" v-if="$v.form.last_name.$error">
                          <span v-if="!$v.form.last_name.required">The Last name is required</span>
                          <span v-if="!$v.form.last_name.minLength">
                            The Last name must have at least {{$v.form.last_name.$params.minLength.min}} characters
                          </span>
                          <span v-if="!$v.form.last_name.maxLength">
                            The Last name must be less than {{$v.form.last_name.$params.maxLength.max}} characters
                          </span>
                        </span>
                      </b-form-group>
                      
                      <!-- Email -->
                      <b-form-group class="mb-4">
                        <b-form-input type="email" placeholder="Email" trim
                          :class="{'is-invalid': $v.form.email.$error}" v-model.trim="$v.form.email.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback">
                          <span v-if="!$v.form.email.required">The Email is required</span>
                          <span v-if="!$v.form.email.email">Invalid Email format</span>
                          <span v-if="!$v.form.email.maxLength">
                            The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Password -->
                      <b-form-group class="mb-4">
                        <b-form-input type="password" placeholder="Password" trim
                          :class="{'is-invalid': $v.form.password.$error || (!checkPassword.success && form.password != '' && form.password != null)}"
                          v-model.trim="$v.form.password.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
                          <span v-if="!$v.form.password.required">The Password is required</span>
                          <span v-if="!$v.form.password.maxLength">
                            The Password must be less than {{$v.form.password.$params.maxLength.max}} characters
                          </span>
                        </span>
                        <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
                          <span class="validation_password--text" :class="[checkPassword.level]">
                            {{checkPassword.title}}
                          </span>
                          <span class="validation_password--description">
                            {{checkPassword.description}}
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Confirm Password -->
                      <b-form-group class="mb-4">
                        <b-form-input type="password" placeholder="Confirm Password" trim
                          :class="{'is-invalid': $v.form.password_confirmation.$error}" v-model.trim="$v.form.password_confirmation.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback" v-if="$v.form.password_confirmation.$error">
                          <span v-if="!$v.form.password_confirmation.required">The Password is required.</span>
                          <span v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The password confirmation does not match.</span>
                        </span>
                      </b-form-group>

                      <!-- Phone number -->
                      <b-form-input type="text" placeholder="Phone number" class="mb-4" trim
                        v-model.trim="form.phone"></b-form-input>

                      <!-- Recaptcha -->
                      <vue-recaptcha ref="recaptcha" sitekey="6LfZ-wYfAAAAAP7rkAPUU3RKiJixfyVmN4Ytz0TC" 
                        size="invisible" @verify="validateRecaptcha" @expired="validateRecaptcha">
                      </vue-recaptcha>
                        
                      <!-- Button -->
                      <b-button type="submit" variant="primary" class="mb-2 w-100">Create Account</b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import { required, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators';
import Navbar from '@/components/Auth/Navbar.vue';
import VueRecaptcha from 'vue-recaptcha';
import Footer from '@/components/Footer.vue';


export default {
  name: 'SignUp',
  components: {
    Navbar,
    VueRecaptcha,
    Footer
  },
  data () {
    return {
      form: {
        first_name: this.$route.query.name ? this.$route.query.name : String(),
        last_name: String(),
        email: this.$route.query.email ? this.$route.query.email : String(),
        password: String(),
        password_confirmation: String(),
        phone: String(),
        recaptcha: null,
      },
      load: false,
    }
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.$router.push({ name: 'dashboard' });
    }
  },
  validations: {
    form: {
      first_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      email: { required, email, maxLength: maxLength(60) },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.load = true;
        this.generateRecaptcha();
      }
    },
    validateRecaptcha(response){
      this.$refs.recaptcha.reset();
      this.form.recaptcha = response;

      this.$http.post('register', this.form).then((response) => {
        if (response.status === 200) {
          this.$notify({ group: 'notifications_home', text: response.body.message, type: 'success' });
          this.$router.push({ name: 'register.success', query: {email: this.form.email} });
        }
        this.load = false;
      }, (error) => {
        if (error.status === 400) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        } else if (error.status === 500) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        }
        this.load = false;
      });
    },
    generateRecaptcha(){
      this.$refs.recaptcha.execute();
    },
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
};
</script>
